<template>
  <div v-if="jobProfile">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>{{ jobProfile.job.name }}</BasePageHeading>
      </div>

      <BasePageActions>
        <EditJobButton
          :job="jobProfile.job"
          @jobUpdated="jobUpdated()"
        >
          <span>Edit Assessment</span>
        </EditJobButton>
        <ArchiveAssessmentButton
          v-if="$usesFeature('classic-jobs') && !jobProfile.job.closedAt"
          :job="jobProfile.job"
          @close="jobDeleted()"
        />
        <UnarchiveAssessmentButton
          v-else-if="$usesFeature('classic-jobs') && jobProfile.job.closedAt"
          :job="jobProfile.job"
        />
        <BaseButton
          variant="salmon"
          :disabled="!canEditExams"
          :to="{ name: 'client-assessments-exams', params: { id: jobProfile.job.uuid } }"
        >
          Edit Tests
        </BaseButton>
        <OpenSignUpJobButton
          :job="jobProfile.job"
        />
      </BasePageActions>
    </BasePageHeader>

    <BaseTabBar>
      <BaseTabs>
        <BaseTab
          :to="{ name: 'client-assessments-show', params: { id: jobProfile.job.uuid } }"
          :active="!showAnalytics"
        >
          Candidates
        </BaseTab>
        <BaseTab
          v-if="(jobProfile.attemptsAverageScorePercentage || jobProfile.attemptsAverageMinutesCompleted) && $usesFeature('classic-jobs')"
          :to="{ name: 'client-assessments-analytics', params: { id: jobProfile.job.uuid } }"
          :active="showAnalytics"
        >
          Analytics
        </BaseTab>
      </BaseTabs>
    </BaseTabBar>

    <div
      v-if="$usesFeature('candidate-wizard')"
      class="mb-6 border-b-2"
    >
      <div class="flex justify-between max-w-screen-xl px-6 mx-auto">
        <Tab
          :tabs="tabs"
          :initial-tab="activeTab"
          @activeTab="activeTab = $event"
        >
          <template slot="tab-head-completed">
            Completed
          </template>

          <template slot="tab-head-progress">
            In Progress
          </template>

          <template slot="tab-head-invited">
            Invited
          </template>
        </Tab>
      </div>
    </div>

    <BaseWrapper v-if="jobProfile.job.candidatesCount === 0">
      <AssessmentEmpty>
        <div v-if="jobProfile.job.exams && jobProfile.job.exams.length > 0">
          <p class="mb-6 text-gray-600 text-md">
            You can edit the tests in this assessment up until the first
            candidate takes a test. Once you’re ready, share this link with
            your candidates, or send an invitation:
          </p>
          <div class="mt-10">
            <OpenSignUpJobButton
              :job="jobProfile.job"
            />
          </div>
        </div>
        <div v-if="!jobProfile.job.exams || jobProfile.job.exams.length === 0">
          <p class="mb-6 text-gray-600 text-md">
            You can edit the tests in this assessment up until the first
            candidate takes a test. Add some tests now before you invited
            your first candidate:
          </p>
          <div class="mt-10">
            <BaseButton
              variant="salmon"
              :disabled="!canEditExams"
              :to="{ name: 'client-assessments-exams', params: { id: jobProfile.job.uuid } }"
            >
              Edit Tests
            </BaseButton>
          </div>
        </div>
      </AssessmentEmpty>
    </BaseWrapper>
    <BaseWrapper v-else-if="jobProfile.job.candidatesCount > 0">
      <!-- Analytics -->
      <div
        v-if="showAnalytics"
        class="grid grid-rows-4 gap-8 mb-8 sm:grid-flow-col"
      >
        <ul class="flex flex-col justify-between col-span-4 row-span-4 p-6 space-y-8 text-center bg-white sm:col-span-1">
          <li>
            <p class="text-xs tracking-wider text-gray-700 uppercase">
              Total tests sent
            </p>
            <p class="text-6xl leading-none text-center">
              {{ jobProfile.totalExamsSentCount }}
            </p>
          </li>
          <li>
            <p class="text-xs tracking-wider text-gray-700 uppercase">
              Total tests taken
            </p>
            <p class="text-6xl leading-none text-center">
              {{ jobProfile.completedAttemptsCount }}
            </p>
          </li>
          <li v-if="jobProfile.totalExamsSentCount > 0">
            <p class="text-xs tracking-wider text-gray-700 uppercase">
              Completion
            </p>
            <p class="text-6xl leading-none text-center">
              {{ Math.ceil(jobProfile.completedAttemptsCount / jobProfile.totalExamsSentCount * 100) }}<span class="text-2xl text-gray-600">%</span>
            </p>
          </li>
          <li v-if="jobProfile.averageHoursBetweenCompletion > 0 && jobProfile.averageHoursBetweenCompletion < 24">
            <p class="text-xs tracking-wider text-gray-700 uppercase">
              Average hours<br>
              from sent to complete
            </p>
            <p class="text-6xl leading-none text-center">
              {{ jobProfile.averageHoursBetweenCompletion }}
            </p>
          </li>
          <li v-else-if="jobProfile.averageHoursBetweenCompletion >= 24">
            <p class="text-xs tracking-wider text-gray-700 uppercase">
              Average days<br>
              from sent to complete
            </p>
            <p class="text-6xl leading-none text-center">
              {{ Math.ceil(jobProfile.averageHoursBetweenCompletion / 24) }}
            </p>
          </li>
        </ul>
        <ul class="col-span-4 row-span-2 p-6 text-center bg-white sm:text-left">
          <li>
            <div class="flex justify-between">
              <h3 class="mb-4 text-xs tracking-wider text-gray-700 uppercase">
                Average attempt scores
              </h3>
              <div class="text-xs">
                <span class="inline-block w-4 h-2 mr-2 bg-graph-secondary"></span> Your candidates
                <span class="inline-block w-4 h-2 ml-4 mr-2 bg-graph-primary"></span> All candidates
              </div>
            </div>
            <ul>
              <li
                v-for="(averageScore, examSlug, index) in jobProfile.attemptsAverageScorePercentage"
                :key="index"
                class="items-center sm:flex"
              >
                <div class="mr-2 sm:w-64">
                  <p class="text-lg">
                    {{ examNameFromSlug(examSlug) }}
                  </p>
                  <p class="text-xs tracking-wider text-gray-600 uppercase">
                    {{ examLevelFromSlug(examSlug) }}
                  </p>
                </div>

                <div class="items-center w-full sm:flex">
                  <div class="w-full">
                    <div class="h-2 my-4 bg-gray-300 sm:mx-4 sm:my-0">
                      <div
                        class="h-full bg-graph-secondary"
                        :style="`width: ${averageScore}%`"
                      >
                      </div>
                    </div>
                    <div
                      v-if="jobProfile.examsAverageScorePercentage[examSlug]"
                      class="h-2 my-4 bg-gray-300 sm:mx-4 sm:my-0"
                    >
                      <div
                        class="h-full bg-graph-primary"
                        :style="`width: ${jobProfile.examsAverageScorePercentage[examSlug]}%`"
                      >
                      </div>
                    </div>
                  </div>

                  <p class="text-3xl text-center sm:w-32">
                    {{ averageScore }}<span class="ml-1 text-lg text-gray-600">%</span>
                  </p>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="col-span-4 row-span-2 p-6 text-center bg-white sm:text-left">
          <li>
            <div class="flex justify-between">
              <h3 class="mb-4 text-xs tracking-wider text-gray-700 uppercase">
                Average attempt speed
              </h3>
              <div class="text-xs">
                <span class="inline-block w-4 h-2 mr-2 bg-graph-secondary"></span> Your candidates
                <span class="inline-block w-4 h-2 ml-4 mr-2 bg-graph-primary"></span> All candidates
              </div>
            </div>
            <ul>
              <li
                v-for="(averageSpeed, examSlug, index) in jobProfile.attemptsAverageMinutesCompleted"
                :key="index"
                class="items-center sm:flex"
              >
                <div class="mr-2 sm:w-64">
                  <p class="text-lg">
                    {{ examNameFromSlug(examSlug) }}
                  </p>
                  <p class="text-xs tracking-wider text-gray-600 uppercase">
                    {{ examLevelFromSlug(examSlug) }}
                  </p>
                </div>

                <div class="items-center w-full sm:flex">
                  <div class="w-full">
                    <div class="h-2 my-4 bg-gray-300 sm:mx-4 sm:my-0">
                      <div
                        class="h-full bg-graph-secondary"
                        :style="`width: ${setAverageSpeed(averageSpeed, examSlug) * 100 / examMinutes(examSlug)}%`"
                      >
                      </div>
                    </div>
                    <div
                      v-if="jobProfile.examsAverageMinutes[examSlug]"
                      class="h-2 my-4 bg-gray-300 sm:mx-4 sm:my-0"
                    >
                      <div
                        v-if="jobProfile.examsAverageMinutes[examSlug] > examMinutes(examSlug)"
                        class="h-full bg-graph-primary"
                        :style="`width: 100%`"
                      >
                      </div>
                      <div
                        v-else
                        class="h-full bg-graph-primary"
                        :style="`width: ${Math.round(jobProfile.examsAverageMinutes[examSlug] / examMinutes(examSlug) * 100)}%`"
                      >
                      </div>
                    </div>
                  </div>

                  <div class="flex items-center justify-center text-3xl sm:w-32">
                    <p class="mr-2">
                      {{ setAverageSpeed(averageSpeed, examSlug) }}<span class="text-sm text-gray-600"> mins</span>
                    </p>
                  </div>
                </div>
                <!--
                @Ross This is an integer of the average MINUTES for the same exam
                {{ jobProfile.examsAverageMinutes[examSlug] }}
                NB It _can_ be null (not calculated), but not 0
              -->
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </BaseWrapper>
  </div>
  <div v-else>
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading>–</BasePageHeading>
      </div>
    </BasePageHeader>
    <Loader />
  </div>
</template>

<script>
import ArchiveAssessmentButton from '@components/ClientAssessments/ArchiveAssessmentButton'
import UnarchiveAssessmentButton from '@components/ClientAssessments/UnarchiveAssessmentButton'
import AssessmentEmpty from '@components/ClientAssessments/AssessmentEmpty'
import EditJobButton from '@components/Jobs/EditJobButton'
import Loader from '@components/Loader'
import OpenSignUpJobButton from '@components/Jobs/OpenSignUpJobButton'
import Tab from '@components/Tab'

import { formatDate } from '@utils/formatDate'

import jobsApi from '@api/jobs'

import { mapGetters } from 'vuex'

export default {
  components: {
    ArchiveAssessmentButton,
    AssessmentEmpty,
    EditJobButton,
    Loader,
    OpenSignUpJobButton,
    Tab,
    UnarchiveAssessmentButton
  },

  data() {
    return {
      formatDate,
      jobProfile: null,
      activeTab: this.$route.query.tab ? this.$route.query.tab : 'completed',
      tabs: ['completed', 'progress', 'invited']
    }
  },

  page() {
    return {
      title: this.jobName
    }
  },

  computed: {
    ...mapGetters({
      haveExamsLoaded: 'exams/haveExamsLoaded',
      examNameFromSlug: 'exams/examNameFromSlug',
      examLevelFromSlug: 'exams/examLevelFromSlug',
      specialFeatures: 'organisations/specialFeatures',
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        },
        {
          name: 'Assessments',
          to: 'client-assessments-list'
        }
      ]
    },

    /**
     * @return {Boolean}
     */
    showAnalytics() {
      return this.$route.query.show === 'analytics'
    },

    /**
     * @return String
     */
    assessmentsLink() {
      if (!this.jobProfile.job.wizardSlug) {
        return
      }
      return `${process.env.VUE_APP_SPLASH_URL}/ca/sign-up/${this.jobProfile.job.wizardSlug}`
    },

    /**
     * @return {string}
     */
    jobName() {
      if (!this.jobProfile) {
        return null
      }
      if (!this.jobProfile.job) {
        return null
      }
      return this.jobProfile.job.name
    },

    /**
     * @return {Boolean}
     */
    canEditExams() {
      return this.jobProfile.job.candidatesCount === 0
    }
  },

  created() {
    this.fetchJob()

    if (!this.haveExamsLoaded) {
      this.$store.dispatch('exams/getExams')
    }
  },

  methods: {
    /**
     * Fetch the job profile based on the job id
     */
    fetchJob() {
      this.jobProfile = null

      return jobsApi.job(this.$route.params.id)
        .then(jobProfile => {
          this.jobProfile = jobProfile
        })
        .catch(error => {
          this.jobProfile = null
          throw error
        })
    },

    /**
     * A job has been deleted
     */
    jobDeleted() {
      this.$router.push({ name: 'jobs' })
    },

    /**
     * A job has been updated
     */
    jobUpdated() {
      this.fetchJob()
    },

    examMinutes(examSlug) {
      return this.jobProfile.examMinutes[examSlug]
    },

    setAverageSpeed(averageSpeed, examSlug) {
      if (averageSpeed > this.examMinutes(examSlug)) {
        return this.examMinutes(examSlug)
      } else {
        return averageSpeed
      }
    }
  }
}
</script>
